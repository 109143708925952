<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col ">
          <h6>Configuration</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12 ">
          <label for="">SMS supplier</label>
          <select class="form-control" v-model="config.sms_supplier">
            <option value="">Choice an option</option>
            <option value="zadarma">ZADARMA</option>
            <option value="aws">AWS</option>
            <option value="infobit">INFOBIT</option>
          </select>
          <label>AWS ACCESS KEY</label>
          <input class="form-control" placeholder="" v-model="config.access_key_id">
          <label>AWS SECRET KEY</label>
          <input class="form-control mt-1" placeholder="" v-model="config.secret_access_key">
          <label>AWS REGION</label>
          <input class="form-control mt-1" placeholder="" v-model="config.region">
          <button class="btn btn-success w-100 mt-1" @click="saveConfig">Save configuration</button>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12 ">
          <table class="table table-bordered align-items-center table-sm">
            <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Supplier</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Access</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Secret</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Region</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Options</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in arrayKeys" :key="key">
              <td>
                <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.sms_supplier}}</p>
              </td>
              <td>
                <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.access_key_id}}</p>
              </td>
              <td>
                <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.secret_access_key}}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{item.region}}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0"><span :class="`badge bg-gradient-${item.status ? 'success' : 'danger'}`">{{item.status}}</span></p>
              </td>
              <td class="">
                <button class="btn btn-success btn-sm" @click="setKeys(item)">Use</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Config",
  data: () => ({
    arrayKeys: [],
    config: {
      sms_supplier: "",
      access_key_id: "",
      secret_access_key: "",
      region: "",
    },
  }),
  mounted() {
    this.getConfig();
  },
  methods: {
    async saveConfig() {
      await post("general-config/create", this.config);
      await this.getConfig()
      this.config = {
        sms_supplier: "",
        access_key_id: "",
        secret_access_key: "",
        region: "",
      }
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },

    async getConfig() {
      const { data } = await get("general-config");
      if (data) {
        this.arrayKeys = data
      }
    },
    async setKeys(item){
      await post("general-config/update",{_id:  item._id});
      await this.getConfig()
    }
  }
}
</script>

<style scoped>

</style>
