import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Users from "@/views/Users/index.vue";
import UsersCreate from "@/views/Users/create.vue";
import AgentsDetail from "@/views/Users/agent_detail.vue";
import Config from "@/views/Config/Index.vue";
import Login from "@/views/SignIn.vue";
import Sms from "@/views/sms/Index.vue";
import SmsList from "@/views/sms/List.vue";
import SMSMessages from "@/views/3pApss/Index.vue";
import authGuard from "@/router/authGuard";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
    meta: {
      auth: false,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: Dashboard,
  },
  {
    path: "/users",
    name: "users",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: Users,
  },
  {
    path: "/users-create",
    name: "users-create",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: UsersCreate
  },
  {
    path: "/agents-view",
    name: "agents-view",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: AgentsDetail
  },
  {
    path: "/configuration",
    name: "configuration",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: Config,
  },
  {
    path: "/sms",
    name: "sms",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Sms,
  },
  {
    path: "/sms-list",
    name: "sms-list",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: SmsList,
  },
  {
    path: "/sms-messages",
    name: "sms-messages",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: SMSMessages,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      auth: false,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});
router.beforeEach(authGuard);
export default router;
