<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>SMS Logs</h6>
        </div>
        <div class="col text-end">
          <h6>Balance: {{ balance }}</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <Templates />
    </div>
  </div>
</template>

<script>
import Templates from "@/views/sms/Templates.vue";
import {get} from "@/utils/httpRequest";
export default {
  name: "Index",
  components: {
    Templates
  },
  data: () => ({
    show_tabs: "tab2",
    balance: null
  }),
  mounted() {
    this.getBalance()
  },
  methods: {
    selectTabs(tab){
      console.log(tab)
      this.show_tabs = tab
    },
    async getBalance(){
      const {data} = await get('sms/get-zadarma-balance')
      this.balance = data.balance ? `${data.balance} ${data.currency}`: "0.00 USD"
    }
  }
}
</script>

<style scoped>

</style>
