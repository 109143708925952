<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>SMS Third-Party App</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12 ">
          <label>LOGIN</label>
          <input class="form-control" placeholder="" v-model="login_message">
          <label>WITHDRAW</label>
          <input class="form-control mt-1" placeholder="" v-model="withdraw_message">
          <label>NEW ACCOUNT</label>
          <input class="form-control mt-1" placeholder="" v-model="new_account_message">
          <button class="btn btn-success w-100 mt-1" @click="saveMessages">Save messages</button>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12 ">
          <table class="table table-bordered align-items-center table-sm">
            <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Login</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Withdraw</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">New Account</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in arrayMessages" :key="key">
              <td>{{item.login_message}}</td>
              <td>{{item.withdraw_message}}</td>
              <td>{{item.new_account_message}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {get, post} from "@/utils/httpRequest";

export default {
  name: "Index",
  data: () => ({
    login_message: "",
    withdraw_message: "",
    new_account_message: "",
    arrayMessages: [],
  }),
  mounted() {
      this.getMessages()
  },
  methods: {
    async getMessages(){
      const {data} = await get("sms-third-party-app")
      this.arrayMessages = data
      if(data.length){
        this.login_message = data[0].login_message
        this.withdraw_message = data[0].withdraw_message
        this.new_account_message = data[0].new_account_message
      }
    },
    async saveMessages(){
      if(this.login_message !== '' || this.withdraw_message !== ''  || this.new_account_message !== '')
        await post("sms-third-party-app/create", {
          login_message: this.login_message,
          withdraw_message: this.withdraw_message,
          new_account_message: this.new_account_message
        })
      this.login_message = ''
      this.withdraw_message = ''
      this.new_account_message = ''
      await this.getMessages()
    }
  }

}

</script>


<style scoped>

</style>
