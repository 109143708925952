<template>

  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>SMS LIST</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col">
          <input type="text" class="form-control" placeholder="Type to search by user, agent email or description" v-model="pagination.search" @keyup="manualSearch">
        </div>
        <div class="col">
          <select name="per_page" id="per_page" v-model="pagination.limit" class="form-control mt-1" @click="reloadPaginate(1)">
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-sm table-striped">
          <thead>
          <tr>
            <th>User</th>
            <th>To</th>
            <th>Agent</th>
            <th>Description</th>
            <th>Date</th>
            <th>Response</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in arraySms" :key="index">
            <td class="text-uppercase text-xs font-weight-bold mb-0">{{item.user}}</td>
            <td class="text-uppercase text-xs font-weight-bold mb-0">{{item.to}}</td>
            <td class="text-uppercase text-xs font-weight-bold mb-0"><span v-if="item.agent">{{item.agent.name}}</span></td>
            <td class="text-uppercase text-xs font-weight-bold mb-0">
              <div class="limited-width_30">
                {{item.message}}
              </div>
            </td>
            <td class="text-uppercase text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</td>
            <td class="text-uppercase text-xs font-weight-bold mb-0">
              <div v-b-tooltip.hover :title="item.response.text??item.response.message">
                <span :class="item.response.status === 'success'? 'text-success':'text-danger'">{{ item.response.status === "success" ? "SUCCESS" : "ERROR" }} </span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between">
          <div>
            <p class="text-xs font-weight-bold mb-0">total rows: {{pagination.totalRows}}</p>
          </div>
          <div>
            <v-pagination
                v-model="pagination.page"
                :pages="pagination.pages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="reloadPaginate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get} from "@/utils/httpRequest";

export default {
  name: "Index",
  data: () => ({
    arraySms: [],
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 20,
      search: '',
      totalRows: 0
    },
    sms: {
      user: '',
      to: '',
      message: ''
    }
  }),
  mounted() {
    this.getSms()
  },
  methods: {
    async getSms(){
      const {data} = await get(`sms/find-all-sms?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}`)
      this.arraySms = data.docs
      this.pagination.totalRows = data.count
      this.pagination.page = data.page
      this.pagination.pages = data.pages
    },
    reloadPaginate(page){
      this.pagination.page = page
      this.getSms()
    },
    manualSearch(){
      clearTimeout(this.pagination.searchTimer);
      this.pagination.searchTimer = setTimeout(() => {
        this.reloadPaginate(1)
      }, 2000);
    },
  }
}
</script>

<style scoped>

</style>
