<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">

      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0">

      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">

      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">

      </div>
    </div>
    <div class="row" v-if="chartData">
      <div class="w-100">
        <select class="form-control" @change="getChart" v-model="day_selected">
          <option value="30">30 Dias</option>
          <option value="60">60 Dias</option>
          <option value="90">90 Dias</option>
          <option value="120">120 Dias</option>
          <option value="150">150 Dias</option>
          <option value="180">180 Dias</option>
        </select>
      </div>
      <div class="col" v-if="renderComponent">
        <gradient-line-chart
            id="chart-line"
            title="SMS Graphic"
            description=""
            :chart="chartData"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import {get} from "@/utils/httpRequest";
export default {
  name: "dashboard-default",
  components: {
    GradientLineChart,
  },
  data : () => ({
    iconBackground: "bg-gradient-success",
    arrayTransactions: [],
    totalTransactions: 0,
    totalCommissions: 0,
    arrayUsers: [],
    total_clients: 0,
    total_clients_today: 0,
    total_leads: 0,
    chartData: null,
    day_selected: 30,
    renderComponent: true,
    chartConfig: {
      type: 'line',
      data: null,
      options: {}
    }
  }),
  mounted() {
    this.getTransactionsReport()
    this.getUsersReport()
    this.getCommissionReport()
    this.getChart()
  },
  methods: {
    async getChart(){
      this.renderComponent = false
      const {data} = await get(`transactions/get-chart?days=${this.day_selected}`)
      data.labels = data.labels.reverse()
      this.chartData = data
      this.chartConfig.data = this.chartData
      await this.$nextTick()
      this.renderComponent = true
    },
    async getUsersReport(){
      const {data} = await get("users/report")
      this.arrayUsers = data.clients_today;
      this.total_clients = data.clients
      this.total_leads = data.leads
      this.total_clients_today = data.total_clients_today
    },
   async getTransactionsReport(){
      const {data} = await get("transactions/report")
     this.arrayTransactions = []
     this.totalTransactions = data
    },

    async getCommissionReport(){
      const {data} = await get("commissions/report")
      if(data.length){
        this.totalCommissions = data[0].totalAmount
      }
    },
    vieDetail(item){
      this.$router.push({name:'users-view', query: {id: item._id}})
    }
  }
};
</script>
<style scoped lang="scss">
@media (max-width: 767px) {
  .margin_top_mobile {
    margin-top: 1.5rem !important;
  }
}
</style>
