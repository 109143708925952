<template>
  <div class="w-100">

    <div class="card mb-4">
      <div class="card-header pb-0">
        <h6>Agent details</h6>
      </div>
      <div class="card-body px-4">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <ul style="list-style: none" v-if="user">
              <li><span class="font-weight-bold">Name: </span><span>{{user.name}}</span></li>
              <li><span class="font-weight-bold">Email: </span><span>{{user.email}}</span></li>
              <li><span class="font-weight-bold">Password: </span><span>{{user.password_text}}</span></li>
              <li><span class="font-weight-bold">Status: </span><span>{{user.status?'ACTIVE':'INACTIVE'}}</span></li>
              <li><span class="font-weight-bold">Date registered: </span><span>{{$filters.formatDate(user.created_at)}}</span></li>
            </ul>
            <hr>

          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <label>Name</label>
                <input type="text" class="form-control" v-model="form.name">
                <label>Email</label>
                <input type="text" class="form-control" v-model="form.email">
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">

                <label>Role</label>
                <select type="text" class="form-control" v-model="form.type">
                  <option value="">Seleccione</option>
                  <option value="agent">Agent</option>
                  <option value="administrator">Administrador del sistema</option>
                </select>
                <label>Status</label>
                <select type="text" class="form-control" v-model="form.status">
                  <option value="true">ACTIVE</option>
                  <option value="false">INACTIVE</option>
                </select>
              </div>
<!--              <button  class="btn bg-gradient-success mt-4 w-100" @click="copyUser"> Copy user data</button>-->
              <button  class="btn bg-gradient-success mt-4 w-100" @click="updateProfile"> update user information</button>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 text-center" v-if="user && show_avatar">
            <label>Password. <span class="text-danger">Necesitara hacer login nuevamente</span></label>
            <input type="text" class="form-control" v-model="new_password">
            <button  class="btn bg-gradient-success mt-4 w-100" @click="updatePassword"> update password</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {get, post} from "@/utils/httpRequest";
export default {
  name: "index",
  data: () => ({
    user_id: null,
    user: null,
    tabSelected: 'transactions',
    url_avatar: '',
    show_avatar: true,
    form: {
      name: '',
      email: '',
      percentage: 0,
      password: '',
      type: '',
      phone: '',
      status: true
    },
    new_password: ''
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getProfile()
  },


  methods: {
    async getProfile(){
      const {data} = await get(`users/find-one?id=${this.user_id}`, null)
      this.user = data
      this.form.status = data.status
      this.form.type = data.type
    },
    async selectImage(e){
      this.url_avatar = e.target.files[0]
    },

    async updateProfile(){
      if(this.form.name !== '' && this.form.email !== '' && this.form.type !== ''){
        this.form.password = ''
        this.$swal({
          html: 'Are you sure?',
          showCancelButton: true,
          confirmButtonText: 'YES',
          cancelButtonText: `NO`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await post('users/update', {...this.form, _id: this.user_id})
            await this.getProfile()
            this.form = {
              name: '',
              email: '',
              percentage: 0,
              password: '',
              type: '',
              phone: ''
            }
            this.$swal({
              html: 'Process successfully',
              icon: 'success',
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        })
      }
    },
    async updatePassword(){
      if(this.new_password !== ''){
        await post('users/update-password', {password: this.new_password, _id: this.user_id})
        this.new_password=''
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    }
  }
}
</script>


