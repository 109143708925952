<template>
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
    id="sidenav-main"
  >
    <div class="d-flex justify-content-center">
      <img :src="logo" alt="main_logo" style="width: 100%" />
    </div>
<!--    <div class="sidenav-header">-->
<!--      <i-->
<!--        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"-->
<!--        aria-hidden="true"-->
<!--        id="iconSidenav"-->
<!--      ></i>-->
<!--      <a class="m-0 navbar-brand" href="/">-->
<!--        <img :src="logo" alt="main_logo" style="width: 60%" />-->
<!--&lt;!&ndash;        <span class="ms-1 font-weight-bold">Control de pagos</span>&ndash;&gt;-->
<!--      </a>-->
<!--    </div>-->
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo.jpg";
import { mapState } from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
    };
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
